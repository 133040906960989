export const error = `<style>
.error-500 {
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 200px);
}

@media screen and (max-width: 768px) {
  .error-500 {
    height: auto;
  }
}

.container {
  max-width: 996px;
  display: flex;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    flex-wrap: wrap;
  }
}

.column1 {
  margin-right: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .column1 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 32px;
    margin-top: 130px;
  }
}

.column2 {
  text-align: left;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .column2 {
    padding: 0 3%;
  }
}

.text-500 {
  text-align: left;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 94px;
  font-size: 80px;
  font-style: normal;
  letter-spacing: 0px;
  color: #101010;
  opacity: 1;
  height: 94px;
}

@media screen and (max-width: 768px) {
  .text-500 {
    text-align: center;
    margin: 26px 0 0 0;
  }
}

.text-error-message {
  padding-bottom: 48px;
  text-align: left;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 28px;
  font-size: 24px;
  font-style: normal;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .text-error-message {
    text-align: center;
  }
}

.btn-wrapper {
  margin: 0 auto;
  text-align: initial;
}

@media screen and (max-width: 768px) {
  .btn-wrapper {
    text-align: center;
  }
}

button.home-btn {
  border: 1px solid #B53BFE;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B53BFE;
  border-radius: 16px;
  padding: 11px 16px;
  text-align: center;
  font: normal normal medium 10px/11px Roboto;
  letter-spacing: 0px;
  color: #B53BFE;
  cursor: pointer;
}

</style>
<div class="error-500">
<div class="container">
    <div class="column1">

    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="176.873"
    height="162.496"
    viewBox="0 0 176.873 162.496"
>
    <defs>
        <linearGradient
            id="linear-gradient"
            x1="0.5"
            y1="1"
            x2="0.5"
            gradientUnits="objectBoundingBox"
        >
            <stop
                offset="0"
                stop-color="#21d6d9"
            />
            <stop
                offset="0.999"
                stop-color="#4d53f3"
            />
        </linearGradient>
    </defs>
    <g
        id="Group_8562"
        data-name="Group 8562"
        transform="translate(-564.184 -342.504)"
    >
        <ellipse
            id="Ellipse_11929"
            data-name="Ellipse 11929"
            cx="79.78"
            cy="59.525"
            rx="79.78"
            ry="59.525"
            transform="translate(570.623 375.203)"
            fill="#101010"
        />
        <g
            id="Group_8672"
            data-name="Group 8672"
            transform="translate(552.621 323.752)"
        >
            <circle
                id="Ellipse_11930"
                data-name="Ellipse 11930"
                cx="7.507"
                cy="7.507"
                r="7.507"
                transform="translate(87.242 124.009)"
                fill="#fff"
            />
            <!-- eslint-disable max-len -->
            <path
                id="Path_17226"
                data-name="Path 17226"
                d="M187.106,89.265c-3.1-17.634-11.156-31.661-22-39.54a40.7,40.7,0,0,0-12.227-22.1c-11.683-10.592-28.633-11.8-40.985-2.564-5.292,3.959-9.009,9.22-11.877,15.291l-.419,0c-1.086-1.949-1.991-3.685-3.016-5.342C85.575,17.237,64.563,13.522,48.11,26.417c-7.2,5.641-12.093,14.535-13.878,24.034C21.014,60.692,12.057,80.1,11.582,102.511c-.654,30.865,15.947,67.12,64.5,76.313a137,137,0,0,0,25.417,2.424h0c17.69,0,43.137-3.733,63.9-21.513C183.409,144.319,191.927,116.658,187.106,89.265ZM156.09,145.373c-17.895,11.087-39.825,13.415-55.071,13.415h0a162.065,162.065,0,0,1-21.9-1.511c-41.848-5.733-56.155-28.342-55.591-47.59A38.161,38.161,0,0,1,58.585,72.52c13.639-1.06,27.493-1.6,41.174-1.6h0c13.68,0,27.534.537,41.174,1.6a37.371,37.371,0,0,1,33.858,28.907C178.946,118.51,171.6,135.759,156.09,145.373Z"
                fill="url(#linear-gradient)"
            />
            <!-- eslint-enable max-len -->
            <g
                id="Group_8670"
                data-name="Group 8670"
            >
                <rect
                    id="Rectangle_11578"
                    data-name="Rectangle 11578"
                    width="25.615"
                    height="8.251"
                    transform="translate(121.541 106.825) rotate(-45)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_11579"
                    data-name="Rectangle 11579"
                    width="25.388"
                    height="8.298"
                    transform="translate(139.558 112.595) rotate(-135)"
                    fill="#fff"
                />
            </g>
            <g
                id="Group_8671"
                data-name="Group 8671"
            >
                <rect
                    id="Rectangle_11580"
                    data-name="Rectangle 11580"
                    width="25.615"
                    height="8.251"
                    transform="translate(56.233 106.825) rotate(-45)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_11581"
                    data-name="Rectangle 11581"
                    width="25.388"
                    height="8.298"
                    transform="translate(74.249 112.595) rotate(-135)"
                    fill="#fff"
                />
            </g>
        </g>
    </g>
</svg>


    </div>
    <div class="column2">
        <h1 class="text-500">500</h1>
        <p class="text-error-message">Oops… An error has occurred and the server cannot complete your request. <br> We hope things will be back to normal shortly, so please check back soon!</p>
        <div class="btn-wrapper"><button class="home-btn" onclick="window.location.href='/'">GO BACK HOME</button></div>
    </div>
</div>
</div>`;
