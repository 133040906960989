import { getUserData, UserData, UserType, getUserType } from '@combinativ/user-data';
import { v4 as uuid } from 'uuid';
import { Logger } from './logger';

function getCookieValue(cookieName: string) {
    const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${cookieName}=`))
        ?.split('=')[1];

    return cookieValue ? decodeURIComponent(cookieValue) : cookieValue;
}

function setCookie(cookieName: string, cookieValue: string) {
    document.cookie = `${cookieName}=${encodeURIComponent(cookieValue)}`;
}

function getGuestID() {
    const guestIDFromCookie = getCookieValue('KGID');
    if (guestIDFromCookie) {
        return guestIDFromCookie;
    }

    const guestID = uuid();
    setCookie('KGID', guestID);
    return guestID;
}

function getUserId() {
    try {
        const userIdL1 = JSON.parse(document.querySelector('html')?.getAttribute('data-session-details') || '{}')?.user_id;
        const userIdL2 = document.querySelector('#user-profile')?.getAttribute('data-user-id');
        return userIdL1 || userIdL2;
    } catch (error) {
        return undefined;
    }
}

function getJWT({ logger }: { logger?: Logger }) {
    try {
        const storageValue = localStorage.getItem('account-identity');
        if (!storageValue) {
            return undefined;
        }
        const parsedValue = JSON.parse(storageValue);
        const { idToken } = parsedValue?.auth;
        if (typeof idToken !== 'string') {
            throw new Error('Invalid value in "account-identity" localStorage item');
        }
        return idToken;
    } catch (error) {
        if (logger) {
            logger.warn(`Failed to retrieve JWT from localStorage (userId: ${getUserId()})`, { error });
        }
        return undefined;
    }
}

function getDevID() {
    return getCookieValue('devID') || undefined;
}

export function getUserDataFromBrowser({ logger }: { logger?: Logger } = {}): UserData {
    return getUserData({
        guestID: getGuestID(),
        idJWT: getJWT({ logger }),
        devID: getDevID(),
    });
}

export function getUserTypeFromBrowser({ logger }: { logger?: Logger } = {}): UserType {
    const idJWT = getJWT({ logger });
    return getUserType(idJWT);
}
