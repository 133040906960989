export function handleSystemCrash(error: any) {
    const { body } = document;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
    document.head.appendChild(link);
    const errorDetails =
        process.env.NODE_ENV === 'development'
            ? `
            <div class="reason">
                <p>* ${error.message}</p>
                <pre>${error.stack}</pre>
            </div>
            `
            : '';
    body.innerHTML = `
        <style>
            .error {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 100vh;
                padding: 10px;
                color: #707070;
            }
            .title {
                margin: 26px auto;
                color: #b53bfe;
                font-size: 48px;
                font-weight: 900;
            }
            .subtitle {
                margin: 20px auto;
                color: #b53bfe;
                font-size: 28px;
                font-weight: 700;
                text-align: center;
            }
            .text-lg {
                margin: 10px auto;
                font-size: 18px;
                font-weight: 700;
            }
            .text-md {
                margin: 10px;
                font-size: 14px;
            }
            .text-sm {
                margin: 10px;
                font-size: 12px;
            }
            .reason {
                position: absolute;
                bottom: 0;
                width: calc(100% - 20px);
                margin: 12px auto;
                color: #b53bfe;
                font-size: 10px;
                text-align: center;
            }
            .reason pre {
                color: #707070;
                white-space: normal;
            }
            @media only screen and (min-width: 768px) {
                .reason pre {
                    white-space: pre-wrap;
                }
            }
            .refresh-btn {
                min-width: 96px;
                min-height: 32px;
                margin: 30px 0;
                padding: 8px 16px;
                color: #ffffff;
                background: linear-gradient(90deg,#1566c0,#b53bfe);
                line-height: 16px;
                border: 0;
                border-radius: 16px;
                outline: none;
                box-sizing: border-box;
                cursor: pointer;
            }
            .refresh-btn:hover {
                background: linear-gradient(90deg,#b53bfe,#1566c0);
            }
        </style>
        <div class="error">
            <p class="title">:(</p>
            <p class="subtitle">This site is currently under maintenance</p>
            <p class="text-lg">Please come back later</p>
            <button id="refresh-btn" class="refresh-btn" onclick="window.location.reload()">Refresh</button>
            ${errorDetails}
        </div>
    `;
}
