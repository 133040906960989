import { Platform, setupRenderClient } from '../common';
import { mfeRendererFactory } from './mfe-renderer';
import { loadRootConfig, loadFeatureFlagConfig } from './config-loader';
import { loadUserLocation } from './location-loader';

/**
 * Perform the initial setup of Combinativ.js client. This is called when the page load.
 */
export function bootstrap() {
    const rootConfig = loadRootConfig();
    const featureFlagConfig = loadFeatureFlagConfig();
    const userLocation = loadUserLocation();
    return setupRenderClient({
        platform: Platform.Kiwi,
        rootConfig,
        featureFlagConfig,
        userLocation,
        bootstrap: ({ appLoader, logger }) => {
            mfeRendererFactory({ appLoader, logger });
        },
    });
}
