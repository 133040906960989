import { RootConfig, FeatureFlagConfig } from '@combinativ/config';

export function loadRootConfig(): RootConfig {
    const tag = document.querySelector('script[type=combinativ-config]');
    if (!tag || !tag.textContent) {
        throw new Error('Root config cannot be found on the page');
    }
    return JSON.parse(tag.textContent);
}

export function loadFeatureFlagConfig(): FeatureFlagConfig {
    try {
        const tag = document.querySelector('script[type="featureflag-config"]');
        if (!tag || !tag.textContent) {
            throw new Error('Feature flags could not be found on the page');
        }
        return JSON.parse(tag.textContent);
    } catch (error) {
        console.error('Could not load feature flags from page.', { error });
        return {
            configs: [],
        };
    }
}
