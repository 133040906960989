import { addErrorHandler, getAppStatus, registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import { AppLoader, Logger } from '../common';
import { loadRootConfig } from './config-loader';
import { error } from './mfeError';

export function mfeRendererFactory({ appLoader, logger }: { appLoader: AppLoader; logger: Logger }): void {
    const layoutTemplateElement = document.getElementById('single-spa-layout');
    if (!layoutTemplateElement) {
        throw new Error('No template found with element id "single-spa-layout"');
    }

    const routes = constructRoutes(layoutTemplateElement, {
        errors: {
            // passing empty divs for nvbar and header
            navError: `<div style="width:72px;height:100%;"></div>`,
            headerError: `<div style="width:100%;height:56px;"></div>`,
            mfeError: error,
        },
        loaders: {},
        props: {
            // the props need to be defined here to be usable in the template
            'http-status:404': true,
        },
    });

    const applications = constructApplications({
        routes,
        loadApp: appLoader.loadApp,
    });
    constructLayoutEngine({ routes, applications });

    // This handler argument will be called whenever an app throws an error during a Single-SPA lifecycle
    // Note this handler does not catch any error or unhandled exception outside the Single-SPA lifecycles scope
    // More info on the app status values on https://single-spa.js.org/docs/api/#getappstatus
    addErrorHandler((err) => {
        const appName = err.appOrParcelName;
        const appStatus = getAppStatus(appName);
        const owner = appLoader.getOwnerFromAppName(appName);
        const logData: Record<string, any> = { appName, appStatus, err };
        if (owner) logData.owner = owner;
        logger.error(`The ${appName} app threw an error with status ${appStatus}`, logData);
    });

    const rootConfig = loadRootConfig();
    applications.forEach((application) => {
        if (!rootConfig.apps[application.name]?.ssr) {
            registerApplication(application);
        }
    });

    start({
        urlRerouteOnly: true,
    });
}
