import { CombinativClientAPI, HeadTagAttributes, HeadTags, UserLocation } from '@combinativ/client-api';
import { Logger } from './logger';
import { FeatureFlagsService } from './feature-flags';
import { setHeadElement } from './html-head-tags';
import { getUserDataFromBrowser, getUserTypeFromBrowser } from './user-data';

export function clientApiFactory({
    logger,
    featureFlags,
    userLocation,
}: {
    logger: Logger;
    featureFlags: FeatureFlagsService | undefined;
    userLocation: UserLocation;
}): CombinativClientAPI {
    return {
        getFeatureFlagValue: featureFlags?.getValue || (() => undefined),
        observeFeatureFlagValue: featureFlags?.observeValue || (() => () => {}),
        getUserLocation: () => userLocation,
        getUserData: () => getUserDataFromBrowser({ logger }),
        getUserType: () => getUserTypeFromBrowser({ logger }),
        sendLog: (level, identificationInfo, message, data) => {
            logger.rawLog(level, identificationInfo, message, data);
        },
        setTitle: (title: string) => {
            setHeadElement(HeadTags.TITLE, undefined, title);
        },
        setDescription: (description: string) => {
            setHeadElement(HeadTags.META, {
                name: 'description',
                content: description,
            });
        },
        setCanonicalUrl: (href: string) => {
            setHeadElement(HeadTags.LINK, {
                href,
                rel: 'canonical',
            });
        },
        setMeta: (attributes: HeadTagAttributes<HeadTags.META>) => {
            setHeadElement(HeadTags.META, attributes);
        },
    };
}
