import { EventEmitter } from 'events';
import { FeatureFlagValue, GetFeatureFlagValueParams, ObserveFeatureFlagValueParams, Unsubscribe } from '@combinativ/client-api';
import { FeatureFlagConfig } from '@combinativ/config';
import { UserData } from '@combinativ/user-data';

import { Logger } from '../logger';

export interface FeatureFlagSettings {
    settingKey: string;
    settingValue: FeatureFlagValue;
}

export interface EvaluatedFeatureFlagConfig {
    configs: {
        productName: string;
        configName: string;
        values: FeatureFlagSettings[];
    }[];
}

export interface FeatureFlagsParams {
    productName: string;
    configName: string;
    userData: UserData;
}

export interface FeatureFlagsUpdater {
    listenForChanges(params: FeatureFlagsParams & { onChange: (values: FeatureFlagSettings[]) => void }): void;
    getInitialValues(params: FeatureFlagsParams & { featureFlagConfig: FeatureFlagConfig }): Promise<FeatureFlagSettings[]>;
}

async function fetchFeatureFlagsFromCacheRaw(): Promise<Response> {
    const response = await fetch(process.env.FEATURE_FLAGS_URL!, {
        mode: 'cors',
        credentials: 'omit',
    });

    return response;
}

export async function fetchFeatureFlagsFromCache(): Promise<FeatureFlagConfig> {
    const response = await fetchFeatureFlagsFromCacheRaw();
    return response.json();
}

export async function fetchFeatureFlagsFromCacheWithEtag(): Promise<{ featureFlagConfig: FeatureFlagConfig; etag: string }> {
    const response = await fetchFeatureFlagsFromCacheRaw();
    return {
        featureFlagConfig: await response.json(),
        etag: response.headers.get('etag') || Date.now().toString(),
    };
}

export async function featureFlagsServiceFactory({
    logger,
    userData,
    featureFlagConfig,
    featureFlagsUpdater,
}: {
    logger: Logger;
    userData: UserData;
    featureFlagConfig: FeatureFlagConfig;
    featureFlagsUpdater: FeatureFlagsUpdater;
}) {
    const valuesMap = new Map<string, Map<string, FeatureFlagValue>>();
    const changeEvents = new EventEmitter();

    await Promise.all(
        featureFlagConfig.configs.map(async (config) => {
            const { productName, configName } = config;

            const values = new Map<string, FeatureFlagValue>();
            valuesMap.set([productName, configName].join('|'), values);

            const initialValues = await featureFlagsUpdater.getInitialValues({ configName, productName, userData, featureFlagConfig });
            initialValues.forEach(({ settingKey, settingValue }) => {
                values.set(settingKey, settingValue);
            });

            // use the provided updater to listen for changes to feature flags.
            featureFlagsUpdater.listenForChanges({
                configName,
                productName,
                userData,
                onChange: (results) => {
                    results.forEach(({ settingKey, settingValue }) => {
                        const previousValue = values.get(settingKey);

                        const valueChanged = previousValue !== settingValue;
                        if (valueChanged) {
                            values.set(settingKey, settingValue);
                        }

                        // When initializing, no need to emit an event
                        const shouldEmit = previousValue !== undefined && valueChanged;
                        if (shouldEmit) {
                            changeEvents.emit([productName, configName, settingKey].join('|'), settingValue, previousValue);
                        }
                    });
                },
            });
        })
    );

    function getValue<T extends FeatureFlagValue>({ productName, configName, flagKey }: GetFeatureFlagValueParams): T | undefined {
        try {
            const values = valuesMap.get([productName, configName].join('|'));
            if (!values) {
                throw Error(`Could not retrieve feature flag values for product "${productName}" and config "${configName}".`);
            }

            return values.has(flagKey) ? (values.get(flagKey) as T) : undefined;
        } catch (error) {
            logger.error(`Could not retrieve feature flag for product (${productName}), config (${configName}), featureflag (${flagKey})`, {
                error,
                productName,
                configName,
                flagKey,
            });
            return undefined;
        }
    }

    function observeValue<T extends FeatureFlagValue>(params: ObserveFeatureFlagValueParams<T>): Unsubscribe {
        const { productName, configName, flagKey, onChange } = params;
        const initialValue = getValue<T>(params);
        const eventKey = [productName, configName, flagKey].join('|');

        changeEvents.on(eventKey, onChange);

        // we pass the same value for the previousValue parameter for the initial event
        onChange(initialValue, initialValue);

        return () => {
            changeEvents.removeListener(eventKey, onChange);
        };
    }

    return { getValue, observeValue };
}

export type FeatureFlagsService = Awaited<ReturnType<typeof featureFlagsServiceFactory>>;
